import React, {useEffect, useState} from 'react';
import './DeliveryBlock.scss';
import {Formik, useFormikContext} from "formik";
import {SimpleBlock} from "../SimpleBlock";
import * as Yup from "yup";
import Company from "../../api/Company";
import {DeliveryBlockForm} from "./DeliveryBlockForm";
import {ProductsForm} from "../ProductsBlock";
import {SimpleModalBlock} from "../SimpleModalBlock";

export const DeliveryBlock = ({children, contentLabel, ...props}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [text, setText] = useState('Доставка не обрана');

    const {
        values: { delivery, phone },
        setFieldValue,
    } = useFormikContext();

    useEffect(  () => {
        if(delivery && delivery.type){
            setText(delivery.type);
        }
    }, [delivery])


    const defaultValues = {
        type: "novapost",
        type_shipment: "box",
        city: "",
        warehouse: "",
        phone: "",
    };

    const initialValues = {
        ...defaultValues,
        ...delivery,
        phone
    }

    const SignInSchema = Yup.object().shape({
        type: Yup.string().required(),
        city: Yup.string().when('type', {
            is: 'novapost',
            then: () => Yup.string().required('Місто обов’язкове.'),
            otherwise: () => Yup.string().notRequired(),
        }),
        warehouse: Yup.string().when('type', {
            is: 'novapost',
            then: () => Yup.string().required('Відділення обов’язкове.'),
            otherwise: () => Yup.string().notRequired(),
        }),
    });

    const onSubmit = async (values) => {
        console.log(values);
        if(values){
            const result = true;//await Company.create(values);
            if(result){
                setFieldValue('delivery', values)
                setIsOpen(false)
            }
        }
    };

    const modalContent = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={SignInSchema}
                onSubmit={onSubmit}
            >
                <DeliveryBlockForm/>
            </Formik>
        )
    }

    return (
        <SimpleModalBlock modalContent={modalContent()} contentLabel="Доставка" modal={{modalIsOpen, setIsOpen}} {...props}>
            {text}
        </SimpleModalBlock>
    );
};