import React from 'react'
import {AuthProvider} from "./context/AuthContext";
import Assets from "./Assets";
import AppRouter from "./AppRouter";

function App() {
  return (
      <AuthProvider>
          <Assets>
              <AppRouter/>
          </Assets>
      </AuthProvider>
  );
}

export default App;