import React, {useEffect, useState} from 'react';
import './ProductsBlock.scss';
import {FieldArray, Form, Formik, useFormikContext} from "formik";
import {SimpleBlock} from "../SimpleBlock";
import * as Yup from "yup";
import {ProductsForm} from "./ProductsForm";
import Company from "../../api/Company";
import {ProductCart} from "../ProductCart/ProductCart";
import {Input} from "../field-components-new/Input";
import Product from "../../api/Product";
import * as arrayHelpers from "formik";
import {FaTrashAlt} from "react-icons/fa";
import {SimpleModalBlock} from "../SimpleModalBlock";
import {InputNumber} from "../field-components-new/InputNumber";

export const ProductsBlock = ({children, contentLabel, ...props}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [text, setText] = useState('Товар не обраний');

    const {
        values: { products },
        setFieldValue,
    } = useFormikContext();

    const defaultValues = {

    };

    const initialValues = {
        ...defaultValues,
        ...products
    }

    const SignInSchema = Yup.object().shape({
        edrpou: Yup.string().min(8).max(10).required(),
    });

    const onSubmit = async (values) => {
        if(values){
            setFieldValue('company', values)
            setIsOpen(false)
        }
    };

    const [productList, setProductList] = useState(false);
    const onSubmit2 = async (values) => {
        const result = await Product.getProducts(values).then(function (response) {
            setProductList(response.data.data);
        });
    }

    const calculateProductTotal = (price, count) => {
        const toSafeNumber = (val) => {
            if (typeof val === 'number') return val;
            if (!val || typeof val !== 'string') return 0;
            return Number(val.replace(/\s/g, '').replace(',', '.')) || 0;
        };

        const safePrice = toSafeNumber(price);
        const safeCount = toSafeNumber(count);

        const total = safePrice * safeCount;

        return Number(total.toFixed(2)); // ✅ Округление до 2 знаков
    };

    const modalContent = () => {
        return (
            <div className="crm__select-product">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit2}
                >
                    <ProductsForm/>
                </Formik>

                <div className="items">
                    {productList && productList.map((item, key) => {
                        const clickItem =  () => {
                            setFieldValue('products', [...products, {...item, count: 1}])
                            setIsOpen(false)
                        }
                        return (
                            <div className="item-wrapper" key={key}>
                                <div className="item" onClick={clickItem}>
                                    <div className="container-img"></div>
                                    <h4 className="name">{item.name} ({item.id})</h4>
                                    <span className="price">Ціна: {item.price} грн.</span>
                                    <span className="price">Наявність: {key} шт.</span>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
        )
    }

    return (
        <div className="ProductsBlock">
            <SimpleModalBlock modalContent={modalContent()} contentLabel="Товар" modal={{modalIsOpen, setIsOpen}} {...props}>
                <FieldArray
                    name="products"
                    render={arrayHelpers => (
                        <div className="list">
                            {products && products.length > 0 ? (
                                products.map((product, index) => (
                                    <div className="item" key={index}>
                                        <div className="left">
                                            <div className="remove">
                                                <button type="submit" className="button-remove" onClick={() => arrayHelpers.remove(index)}>
                                                    <FaTrashAlt />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="name">
                                                <a href="#">
                                                    {product.name}
                                                </a>
                                            </div>
                                            <div className="action">
                                                <div className="price">
                                                    <InputNumber label="Ціна" name={`products.${index}.price`}/>
                                                </div>
                                                <div className="count">
                                                    <Input label="Кількість" name={`products.${index}.count`}/>
                                                </div>
                                            </div>
                                            <div className="count-price">
                                                {calculateProductTotal(product.price, product.count)} грн.
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    Список пустий
                                </>
                            )}
                        </div>

                    )}
                />
            </SimpleModalBlock>
        </div>
    );
};