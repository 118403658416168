import React, {useEffect} from 'react';
import {Form, useFormikContext} from "formik";
import {Input} from "../field-components-new/Input";
import {Button} from "../Button/Button";
import Company from "../../api/Company";
import NbuApi from "../../api/NbuApi";
import {MainSelect} from "../field-components-new/Select";
import {CityDropdownField} from "../field-components-new/CityDropdownField";
import {WarehouseDropdownField} from "../field-components-new/WarehouseDropdownField";

export const DeliveryBlockForm = () => {
    const {
        values: { type },
        setFieldValue,
        errors,
        isSubmitting
    } = useFormikContext();

    const types = [
        { value: 'novapost', label: 'Нова Пошта' },
        // { value: 'novapost-k', label: "Нова Пошта - Кур'єр" },
        // { value: 'uapost', label: 'Укрпошта' },
        { value: 'other', label: 'Самовивоз' }
    ];
    const types_shipment = [
        { value: 'box', label: "Посилка" },
        { value: 'document', label: 'Документи' },
        { value: 'palet', label: 'Палета' },
    ]


    return (
        <Form>
            <div className="tf-form__row">
                <div className="tf-form__col w100">
                    <MainSelect label="Тип доставки" items={types} name="type" placeholder="виберіть тип доставки"/>
                </div>
            </div>
            <div className="tf-form__row">
                <div className="tf-form__col w100">
                    <MainSelect  label="Тип вантажу" items={types_shipment} name="type_shipment" placeholder="виберіть тип вантажу"/>
                </div>
            </div>
            {type == 'novapost' ? (
                <div className="tf-form__row">
                    <div className="tf-form__col w50">
                        <CityDropdownField  label="Населений пункт" name="city" placeholder="виберіть населений пункт"/>
                    </div>
                    <div className="tf-form__col w50">
                        <WarehouseDropdownField  label="Відділення" name="warehouse" placeholder="виберіть відділення"/>
                    </div>
                </div>
            ) : null}

            <div className="tf-form__row justify-content-center">
                <Button
                    submit
                    className={isSubmitting ? "disabled-btn" : ""}
                    disabled={isSubmitting}
                >
                    Продовжить
                </Button>
            </div>

        </Form>
    )
};