import React from 'react';

import {useField, useFormikContext} from 'formik';
import {FormGroup} from '../FormGroup';
import './CityDropdownField.scss';
import Client from "../../../api/Client";
import AsyncSelect from "react-select/async";
import NovaPost from "../../../api/NovaPost";

export const CityDropdownField = ({label, items, validate, placeholder, table, autoSubmit, ...props}) => {
    const {id, name} = props;
    const [
        {value, onBlur: onBlurFormik},
        {error, touched},
        {setValue, setTouched, setError}
    ] = useField(props);

    const {
        values: { type },
    } = useFormikContext();

    const loadOptions = (searchKey) => {
        return new Promise(async (resolve) => {
            if (searchKey.length >= 2) {
                const result = await NovaPost.getCities(searchKey);
                let result_data = result.data;
                console.log(result)
                if (result.status === 200 && result.data.length > 0) {
                    resolve(result.data);
                } else {
                    resolve([]);
                }
            }
        });
    };

    const onChange = (option) => setValue(option.city_ref);

    return (

        <FormGroup error={touched ? error : null} {...{id, name, label}} >
            <AsyncSelect
                onChange={onChange}
                value={value.city_ref}
                loadOptions={loadOptions}
                placeholder={placeholder}
                getOptionLabel={(e) => e.city}
                getOptionValue={(e) => e.city_ref}

                isDisabled={type === ''}
            />
        </FormGroup>
    );
};
