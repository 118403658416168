import React from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import { FormGroup } from '../FormGroup';

import './TextArea.scss';


export const TextArea = ({ label, mask, disabled, ...props}) => {
    const { id, name, type, placeholder } = props;
    const {...fieldProps } = props;

    const [field, { error, touched }, { setError, setTouched }] = useField(fieldProps);

    const inputClasses = cx(
        'textarea',
        { 'input--error': touched && error != null},
        { 'input--success': touched && error == null},
    );

    return (
        <FormGroup {...{ label, id, name }}>
            <span className="lcz-textarea__container">
                <textarea className={inputClasses}
                          {...field}
                          {...{
                              name,
                              placeholder,
                          }}>

                </textarea>
            </span>
        </FormGroup>
    );
};