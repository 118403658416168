import React, {useEffect} from 'react';
import cx from 'classnames';
import {useField, useFormikContext} from 'formik';
import { FormGroup } from '../FormGroup';

import './MainSelect.scss';
import Select from "react-select";


export const MainSelect = ({label, items, validate, placeholder = "Виберіть", table, autoSubmit, ...props}) => {
    const {id, name} = props;
    const [
        {value, onBlur: onBlurFormik},
        {error, touched},
        {setValue, setTouched, setError}
    ] = useField(props);

    const defaultOptions = [
        { value: 'fop', label: 'ФОП' },
        { value: 'tov', label: 'ТОВ' },
        { value: 'other', label: 'Інше' }
    ]

    const initialOptions = items ?? defaultOptions;


    const onChange = (option) => setValue(option.value);

    return (
        <FormGroup {...{ label, id, name }}>
            <Select defaultValue={initialOptions[0]}
                    onChange={onChange}
                    value={initialOptions.filter(function(option) {
                        return option.value === value;
                    })}
                    options={initialOptions}
                    placeholder={placeholder}
                    {...props}
            />
        </FormGroup>
    );
};