import Logout from "../pages/auth/Logout";
import Login from "../pages/auth/Login";
import Registration from "../pages/auth/Registration";
import ProductList from "../pages/Product/ProductList";
import ProductCreate from "../pages/Product/ProductCreate";
import ProductEdit from "../pages/Product/ProductEdit";
import ProductShow from "../pages/Product/ProductShow";
import OrderList from "../pages/Order/OrderList";
import OrderCreate from "../pages/Order/OrderCreate";

export const appRoutes = [
    { path: '/login', element: <Login />, title: 'Вхід' },
    { path: '/logout', element: <Logout />, title: 'Вихід' },
    { path: '/signup', element: <Registration />, title: 'Реєстрація' },

    { path: '/products', element: <ProductList />, title: 'Товари', protected: true },
    { path: '/products/create', element: <ProductCreate />, title: 'Новий товар', protected: true },
    { path: '/products/edit/:id', element: <ProductEdit />, title: 'Редагування товару', protected: true },
    { path: '/products/show/:id', element: <ProductShow />, title: 'Перегляд товару', protected: true },

    { path: '/orders', element: <OrderList />, title: 'Замовлення', protected: true },
    { path: '/orders/create', element: <OrderCreate />, title: 'Нове замовлення', protected: true },
];