import React, { useState, useEffect } from 'react'
import {useNavigate} from "react-router-dom"
import Swal from 'sweetalert2'
import {HeaderNav} from "../../components/HeaderNav";
import {GoPlus} from "react-icons/go";
import {ButtonAdd} from "../../components/ButtonAdd/ButtonAdd";
import {Form, Formik} from "formik";
import {MdEdit} from "react-icons/md";
import {FaRegTrashAlt} from "react-icons/fa";
import './OrderList.scss';
import {ButtonList} from "../../components/ButtonList/ButtonList";
import {SubmitContext} from "../../context/SubmitContext";
import {ListFilter} from "../../components/ListFilter";
import {SearchField} from "../../components/field-components/SearchField";
import Order from "../../api/Order";
import axiosInstance from "../../utils/axiosInstance";
import {FiMoreVertical} from "react-icons/fi";

function OrderList() {
    const navigate = useNavigate();
    const [productList, setProductList] = useState([])
    const [pagination, setPagination] = useState({})

    const [numberPages, setNumberPages] = useState( {'label': '5', 'value': 5})

    const fetchOrderList = (searchText) => {
        let params = {};
        if(searchText) params.search = searchText;
        axiosInstance.get('/order', {params})
            .then(function (response) {
                setProductList(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Ви впевнені?',
            text: "Ви не зможете скасувати це!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Так, видалити!',
            cancelButtonText: 'Ні',
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`/order/${id}`)
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Товар успішно видалено!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchOrderList()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Виникла помилка!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }


    function openModal() {
        navigate('/orders/create');
    }

    const getOrders = async (data = {}) => {
       // let newData = {...data, 'per_page' : numberPages.value}
        const result = await Order.get(data).then(function (response) {
            setProductList(response.data.data);
            setPagination(response.data.pagination);
        });
    }

    useEffect( () => {
        //getOrders()
    }, [numberPages]);

    const [resultSubmit, setResultSubmit] = useState({'success' : null});

    const onSubmit = async (values) => {
        await getOrders(values)
    };

    const defaultInitialValues = {
        search: '',
        per_page: 10,
        page: 1,
        status: '',
    };

    const initialValues = {
        ...defaultInitialValues
    }

    const formikOptions = {
        initialValues,
        onSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    };

    return (
        <div>
            <HeaderNav title="Замовлення">
                <ButtonAdd onClick={openModal}>
                    <GoPlus size="18px"/>
                </ButtonAdd>
            </HeaderNav>

            <SubmitContext.Provider
                value={{
                    resultSubmit,
                    setResultSubmit
                }}
            >
                <Formik {...formikOptions}>
                    {({isSubmitting, isValid, dirty}) => (
                        <Form>
                            <ListFilter>
                                <SearchField type="text" name="search"></SearchField>
                            </ListFilter>

                            <div className="table-container">
                                <table className="crm-table">
                                    <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>Статус</th>
                                        <th>Телефон</th>
                                        <th>Покупець</th>
                                        <th>Продавець</th>
                                        <th>Оплата</th>
                                        <th className="actions"></th>
                                    </tr>
                                    </thead>
                                </table>
                                <div className="table-scroll">
                                    <table className="crm-table">
                                        <tbody>
                                        {productList.map((item, key) => (
                                            <tr key={key}>
                                                <td data-label="№">{item.id}</td>
                                                <td data-label="Статус">{item.status ? 'Отримано' : 'Видалено'}</td>
                                                <td data-label="Телефон">{item.phone}</td>
                                                <td data-label="Покупець">{item.first_name} {item.last_name} {item.middle_name}</td>
                                                <td data-label="Продавець">Бігун Владислав</td>
                                                <td data-label="Оплата"><span className="badge unpaid">Не оплачено</span></td>
                                                <td data-label="" className="actions">
                                                    <div className="crm-dropdown">
                                                        <button className="crm-dropdown-toggle">
                                                            <FiMoreVertical size={20} />
                                                        </button>
                                                        <div className="crm-dropdown-menu">
                                                            <button onClick={() => navigate(`/orders/edit/${item.id}`)}>Редагувати</button>
                                                            <button onClick={() => handleDelete(item.id)}>Видалити</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                            {/*<ListPagination pagination={pagination}/>*/}
                        </Form>
                    )}
                </Formik>
            </SubmitContext.Provider>

        </div>
    );
}

export default OrderList;