// src/app/AppRouter.jsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import {MainContainer} from "./components/MainContainer";
import {appRoutes} from "./router/routes";
import TitleUpdater from "./utils/TitleUpdater";


const AppRouter = () => {
    return (
        <Router>
            <MainContainer>
                <TitleUpdater routes={appRoutes} />
                <Routes>
                    {appRoutes.map(({ path, element, protected: isProtected }, idx) =>
                        isProtected ? (
                            <Route key={idx} element={<ProtectedRoute />}>
                                <Route path={path} element={element} />
                            </Route>
                        ) : (
                            <Route key={idx} path={path} element={element} />
                        )
                    )}
                </Routes>
            </MainContainer>
        </Router>
    );
};

export default AppRouter;
