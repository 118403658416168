import React, {useEffect} from 'react'
import {Link, useNavigate} from "react-router-dom"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik";
import "./OrderCreate.scss";
import * as Yup from 'yup';
import {Input} from "../../components/field-components-new/Input";
import Order from "../../api/Order";
import {SearchDropdownField} from "../../components/field-components-new/SearchDropdownField";
import {HeaderNav} from "../../components/HeaderNav";
import {CompanyBlock} from "../../components/CompanyBlock";
import {DeliveryBlock} from "../../components/DeliveryBlock";
import {Button} from "../../components/Button/Button";
import {ProductsBlock} from "../../components/ProductsBlock";
import {SimpleBlock} from "../../components/SimpleBlock";
import {TextArea} from "../../components/field-components-new/TextArea";
import {MainSelect} from "../../components/field-components-new/Select";



function OrderCreate() {
    const navigate = useNavigate();

    const initialValues = {
        phone: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        edrpou: "",
        name_company: "",
        iban_company: "",
        address_company: "",
        type_company: "ФОП",
        comment: 'test',
        products: [],
        company: {},
        status: 1,
        status_pay: 1,
        seller: null,
        type_pay: null,
    };

    const SignInSchema = Yup.object().shape({
        phone: Yup.string().required().min(12),
        first_name: Yup.string().required().min(3),
        last_name: Yup.string().required().min(3),
        middle_name: Yup.string().nullable(),
        comment: Yup.string(),
        status: Yup.number().required(),
        status_pay: Yup.number().required(),
        seller: Yup.number().required(),
        type_pay: Yup.number().required(),
    });

    const onSubmit = async (values) => {

        const gValues = {
            ...values,
            'phone': values.phone.replace(/[^\d]/g, '')
        }
        const result = await Order.create(gValues);
        if (result.success) {
            console.log(result)
            navigate("/orders");
        }
    };

    const ClientFields = (props) => {
        const {
            values: { search },
            setFieldValue,
            errors,
        } = useFormikContext();
        // const [field, meta] = useField(props);

        useEffect( () => {
            if (search) {
                setFieldValue('phone', search.phone);
                setFieldValue('last_name', search.last_name);
                setFieldValue('first_name', search.first_name);
                setFieldValue('middle_name', search.middle_name);
            }
        }, [search])

        return (
            <SimpleBlock contentLabel="Клієнт">
                <div className="tf-form__row">
                    <div className="tf-form__col w100">
                        <SearchDropdownField name="search" placeholder="Номер телефону або ПІБ клієнта"/>
                    </div>
                </div>
                <div className="tf-form__row">
                    <div className="tf-form__col w50">
                        <Input type="text" name="phone" label="Номер телефону"
                               mask="+38 (099) 999 99 99"></Input>
                    </div>
                    <div className="tf-form__col w50">
                        <Input type="text" name="first_name" label="Прізвище"></Input>
                    </div>
                </div>
                <div className="tf-form__row">
                    <div className="tf-form__col w50">
                        <Input type="text" name="last_name" label="Імя"></Input>
                    </div>
                    <div className="tf-form__col w50">
                        <Input type="text" name="middle_name"
                               label="По батькові"></Input>
                    </div>
                </div>
            </SimpleBlock>
        )
    }
    const statusItems = [
        { value: 1, label: 'Створено' },
        { value: 2, label: 'Предзамовлення' },
        { value: 3, label: 'Оброблено' },
        { value: 4, label: 'Відправлено' },
        { value: 5, label: 'Отримано' },
        { value: 6, label: 'Завершено' },
        { value: 10, label: 'Видалено' },
    ];
    const sellerItems = [
        { value: 1, label: 'Продавець 1' },
    ];
    const typePayItems = [
        { value: 1, label: 'Безготівка' },
        { value: 3, label: 'Готівка' },
        { value: 5, label: 'Накладний платіж' },
    ];
    const statusPayItems = [
        { value: 1, label: 'Не оплачено' },
        { value: 2, label: 'В процесі' },
        { value: 3, label: 'Оплачено' },
    ];

    return (
        <div>
            <HeaderNav title="Створення замовлення"></HeaderNav>

            <div className="container mt-4">
                <Formik
                    initialValues={initialValues}
                    validationSchema={SignInSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => {

                        const {
                            errors,
                            touched,
                            isSubmitting, isValid,
                            dirty, values,
                            setFieldValue,
                            value,
                        } = formik;

                        return (
                            <div>
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <SimpleBlock>
                                                <div className="tf-form__row">
                                                    <div className="tf-form__col w25">
                                                        <MainSelect items={sellerItems} name="seller" label="Продавець"></MainSelect>
                                                    </div>
                                                    <div className="tf-form__col w25">
                                                        <MainSelect items={statusItems} name="status" label="Статус"></MainSelect>
                                                    </div>
                                                    <div className="tf-form__col w25">
                                                        <MainSelect items={typePayItems} name="type_pay" label="Тип оплати"></MainSelect>
                                                    </div>
                                                    <div className="tf-form__col w25">
                                                        <MainSelect items={statusPayItems} name="status_pay" label="Статус оплати"></MainSelect>
                                                    </div>
                                                </div>
                                            </SimpleBlock>
                                            <ProductsBlock/>
                                        </div>
                                        <div className="col-lg-5">

                                            <ClientFields></ClientFields>

                                            {/*<div className="tf-form__col w33">*/}
                                            {/*    <TextArea name="comment"></TextArea>*/}
                                            {/*</div>*/}

                                            <CompanyBlock></CompanyBlock>
                                            <DeliveryBlock></DeliveryBlock>
                                            <SimpleBlock contentLabel="Інше">
                                                <TextArea label="Коментар" name="comment"></TextArea>

                                            </SimpleBlock>

                                        </div>
                                    </div>

                                    <Button
                                        submit
                                        className={isSubmitting ? "disabled-btn" : ""}
                                        disabled={isSubmitting}
                                    >
                                        Продовжить
                                    </Button>
                                </Form>
                            </div>
                        );
                    }}
                </Formik>
            </div>


        </div>
    )
        ;
}

export default OrderCreate;