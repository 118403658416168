import React from 'react';
import './SimpleBlock.scss';
import cx from "classnames";

export const SimpleBlock = ({children, contentLabel, modal, ...props}) => {
    const { className} = props;
    const blockClasses = cx(
        'simple-block',
        className,
    );

    return (
        <div className={blockClasses}>
            <div className="wrapper">
                <div className="header">
                    <span>{contentLabel}</span>
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
        </div>
    );
};