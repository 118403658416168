import React, {useEffect} from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask-next';
import {useField, useFormikContext} from 'formik';
import { FormGroup } from '../FormGroup';

import './Input.scss';

const CustomMaskedInput = React.forwardRef(
    ({ maskChar, format, allowNegative, ...props }, ref) => {
        return <input ref={ref} {...props} />;
    }
);

export const Input = ({ label, mask, disabled, autoSubmit = false, ...props}) => {
    const { id, name, type, placeholder } = props;
    const {...fieldProps } = props;
    const [field, { error, touched }] = useField(fieldProps);
    const { value } = field;

    const inputClasses = cx(
        'input',
        { 'input--error': touched && error != null},
        { 'input--success': touched && error == null},
    );
    const { submitForm } = useFormikContext();

    useEffect(() => {
        if (autoSubmit === true) {
            submitForm();
        }
    }, [value])

    return (
        <FormGroup {...{ label, id, name }}>
            <InputMask className={inputClasses}
                       customInput={CustomMaskedInput}
                       {...field}
                       {...{
                           id: name,
                           type,
                           placeholder,
                           mask,
                           disabled
                       }}
            />
        </FormGroup>
    );
};