import { useEffect } from 'react';
import { useLocation, matchRoutes } from 'react-router-dom';

const TitleUpdater = ({ routes }) => {
    const location = useLocation();

    useEffect(() => {
        const matches = matchRoutes(routes, location);
        const matchedRoute = matches?.[0]?.route;

        if (matchedRoute?.title) {
            document.title = `${matchedRoute.title} | Bihun CRM`;
        } else {
            document.title = 'Bihun CRM';
        }
    }, [location.pathname, routes]);

    return null;
};

export default TitleUpdater;
