import axios from "axios";
const endpoint = 'https://www.trueflex.com.ua/api/np';

export default class NovaPost {

    static async getCities(data) {
        return await axios.get(endpoint + '/cities', {
            params: {city: data}
        });
    }
    static async getWarehouse(data, city_ref) {
        return await axios.get(endpoint + '/warehouses', {
            params: {searchName: data, cityRef: city_ref}
        });
    }
}