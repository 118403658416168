import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import {HeaderNav} from "../../components/HeaderNav";
import {GoPlus} from "react-icons/go";
import {ButtonAdd} from "../../components/ButtonAdd/ButtonAdd";
import {ItemList} from "../../components/ItemList";
import {SubmitContext} from "../../context/SubmitContext";
import {Formik} from "formik";

import Product from "../../api/Product";
import ProductCategory from "../../api/ProductCategory";
import {ProductCreateModal} from "../../components/ListProductModal";
import axiosInstance from "../../utils/axiosInstance";

function ProductList() {
    const navigate = useNavigate();
    const [productList, setProductList] = useState([])
    const [productEdit, setProductEdit] = useState({})
    const [productCategories, setProductCategories] = useState([])

    const [modalIsOpen, setIsOpen] = useState(false);

    const fetchProjectList = (searchText) => {
        let params = {};
        if(searchText) params.search = searchText;
        axiosInstance.get('/product', {params})
            .then(function (response) {
                setProductList(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Ви впевнені?',
            text: "Ви не зможете скасувати це!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Так, видалити!',
            cancelButtonText: 'Ні',
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`/product/${id}`)
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Товар успішно видалено!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchProjectList()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Виникла помилка!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }


    function openModal() {
        setProductEdit({})
        setIsOpen(true);
    }

    const getProducts = async (data) => {
        const result = await Product.getProducts(data).then(function (response) {
            setProductList(response.data.data);
        });
    }

    useEffect( () => {
        getProducts()
    }, []);

    const [resultSubmit, setResultSubmit] = useState({'success' : null});

    const onSubmit = async (values) => {
        await getProducts(values)
    };

    const defaultInitialValues = {
        search: '',
        category_id: 0,
    };

    const initialValues = {
        ...defaultInitialValues
    }

    const formikOptions = {
        initialValues,
        onSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    };

    const getProductCategories = async() =>{
        if (!productCategories.length) {
            let response = await ProductCategory.getCategories()
            let categories = [];
            response.data.map((item) =>
                categories.push({
                    'label': item.name,
                    'value': item.id,
                })
            );
            setProductCategories(categories)
        }
    }

    useEffect(() => {
        getProductCategories();
    },[]);

    return (
        <div>
            <HeaderNav title="Товари">
                <ButtonAdd onClick={openModal}>
                    <GoPlus size="18px"/>
                </ButtonAdd>
            </HeaderNav>

            <SubmitContext.Provider
                value={{
                    resultSubmit,
                    setResultSubmit
                }}
            >
                <Formik {...formikOptions}>
                    {({isSubmitting, isValid, dirty}) => (
                        <ItemList handleDelete={handleDelete}
                                  products={productList}
                                  productCategories={productCategories}
                                  openModal={setIsOpen}
                                  setProductEdit={setProductEdit}
                        >
                        </ItemList>
                    )}
                </Formik>
            </SubmitContext.Provider>


            <ProductCreateModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} productCategories={productCategories} productEdit={productEdit} getProducts={getProducts}/>
        </div>
    );
}

export default ProductList;