import React, {useCallback, useContext, useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import './ProductCreateModal.scss';
import {Form, Formik} from "formik";
import {TextRequiredField} from "../field-components/TextRequiredField/TextRequiredField";
import {TextArea} from "../field-components/TextArea";
import {VendorCodeField} from "../field-components/VendorCodeField/VendorCodeField";
import {PriceField} from "../field-components/PriceField/PriceField";
import {CategoryProductField} from "../field-components/CategoryProductField";
import {Button} from "../Button/Button";
import Modal from "react-modal";
import {noop} from "bootstrap/js/src/util";
import {AssetsContext} from "../../context/AssetsContext";
import Promise from "lodash/_Promise";
import {createFieldValidator} from "../../utils/create-field-validator";
import * as Yup from "yup";
import {SubmitContext} from "../../context/SubmitContext";
import {VscGitPullRequestCreate} from "react-icons/vsc";
import {SlugField} from "../field-components/SlugField/SlugField";
import Product from "../../api/Product";

export const ProductCreateModal = ({modalIsOpen, setIsOpen, productCategories, productEdit, getProducts}) => {
    const [resultSubmit, setResultSubmit] = useState({'success' : null});

    const customStyles = {
        overlay: {
            background: 'rgb(0 0 0 / 35%)',
        },
        content: {
            top: '0',
            right: '0px',
            height: '100vh',
            width: '40vw',
            left: '60vw',
        },
    };


    const initialErrors = (value, key) => {
        if(key === 'category_id') return '';
        else return ''
    }

    const defaultInitialValues = {
        name: '',
        slug: '',
        price: '',
        category_id: null,
        vendor_code: Date.now(),
        content: '',
        weight: '',
        volumetric_length: '',
        volumetric_width: '',
        volumetric_height: '',
    };

    const tempValues = {
        ...defaultInitialValues,
        name: productEdit.name,
        slug: productEdit.slug,
        price: productEdit.price,
        category_id: productEdit.category_id,
        vendor_code: productEdit.vendor_code,
        content: productEdit.content,
        weight: productEdit.weight,
        volumetric_length: productEdit.volumetric_length,
        volumetric_width: productEdit.volumetric_width,
        volumetric_height: productEdit.volumetric_height,
    }
    const initialValues = productEdit.id ? tempValues : defaultInitialValues;

    const onSubmit = async (values) => {
        if(productEdit.id){
            const result = await Product.editProduct(productEdit.id, values);
            if (result.id) {
                setIsOpen(false);
                getProducts()
            }
        } else {
            const result = await Product.createProduct(values);
            if (result.id) {
                setIsOpen(false);
                getProducts()
                //return new Promise(noop);
            }
        }
    };

    const SignInSchema = Yup.object().shape({
        phone: Yup.string().required('це обов\'язкове поле'),
        first_name: Yup.string().required('це обов\'язкове поле'),
        name: Yup.string().required('це обов\'язкове поле'),
        last_name: Yup.string().required('це обов\'язкове поле'),
    });

    const formikOptions = {
        initialValues,
        onSubmit,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: SignInSchema,
        // initialErrors: mapKeys(initialValues, (value, key) => initialErrors(value, key)),
    };

    function closeModal() {
        setIsOpen(false);
    }

    const requiredValidate = useCallback(createFieldValidator(Yup
        .string()
        .required('Це обов’язкове поле!')
    ), []);

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            closeTimeoutMS={200}
            ariaHideApp={false}
        >
            <div className="crm__product-create-modal">
                <div className="crm__product-create-modal-header">
                    <VscGitPullRequestCreate size="24px"/>
                    <h3>{productEdit.id ? 'Редагування товару' : 'Створення товару'}</h3>
                </div>
                <SubmitContext.Provider
                    value={{
                        resultSubmit,
                        setResultSubmit
                    }}
                >
                    <Formik {...formikOptions}>
                        {({ isSubmitting, isValid, dirty}) => (
                            <Form className="lcz-form">
                                <section className="lcz-form__section">
                                    <TextRequiredField name="name" label="Назва" type="text"/>
                                    <SlugField name="slug" label="ЧПУ" type="text" donorField="name"/>
                                    <TextArea name="content" label="Опис" type="text" validate={requiredValidate}/>
                                    <div className="tf-form__row">
                                        <div className="tf-form__col w50">
                                            <VendorCodeField name="vendor_code" label="Код товару"/>
                                        </div>
                                        <div className="tf-form__col w50">
                                            <PriceField name="price"/>
                                        </div>
                                    </div>
                                    <div className="tf-form__row">
                                        <div className="tf-form__col w25">
                                            <PriceField name="weight" label="Вага (грам)" />
                                        </div>
                                        <div className="tf-form__col w25">
                                            <PriceField name="volumetric_length" label="Довжина (мм)"/>
                                        </div>
                                        <div className="tf-form__col w25">
                                            <PriceField name="volumetric_width" label="Ширина (мм)"/>
                                        </div>
                                        <div className="tf-form__col w25">
                                            <PriceField name="volumetric_height" label="Висота (мм)"/>
                                        </div>
                                    </div>
                                    <div className="tf-form__col w50">
                                        <CategoryProductField name="category_id" label="Категорія" items={productCategories} placeholder="Категорія"/>
                                    </div>
                                    {/*<ImageUploading name="images" label="Зображення"/>*/}
                                </section>
                                <section className="lcz-form__section lcz-form__submit-section">
                                    <div className="lcz-form__checking">
                                    </div>
                                    <p className="lcz-form__submit-row">
                                        <Button submit disabled={isSubmitting}>Продовжити</Button>
                                    </p>
                                </section>

                            </Form>
                        )}
                    </Formik>
                </SubmitContext.Provider>
            </div>
        </Modal>
    );
};

function mapKeys(obj, mapFn) {
    const result = {};
    Object.entries(obj).forEach(([key, value]) => {
        result[key] = mapFn(value, key);
    });
    return result;
}

ProductCreateModal.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.symbol,
    ]),
    info: PropTypes.node,
    className: PropTypes.string,
    hasFocus: PropTypes.bool,
    isValidating: PropTypes.bool,
};
