import {SignupRequestError} from "./SignupRequestError";
import axiosInstance from "../utils/axiosInstance";
const endpoint = 'company';

const processResponse = (response) => {
    const { data } = response;
    if (!data) {
        throw new Error('Unknown response from server');
    } else if (data.success !== true) {
        throw new SignupRequestError(data.error);
    }
    return data;
};

export default class Company {
    static async get(data) {
        return await axiosInstance.get(endpoint, {
            params: data
        });
    }

    static async create(payload) {
        let response = processResponse(await axiosInstance({
            method: 'POST',
            url: endpoint + '/create',
            data: { ...payload },
        }))

        console.log("response",response)
        return response;
    }
}