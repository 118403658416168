import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from "./context/AuthContext";

const ProtectedRoute = () => {
    const { user, checking } = useAuth();

    if (checking) {
        return <div>Завантаження...</div>; // Можно добавить спиннер
    }

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
