import React, {useEffect, useState} from 'react';
import './ModalCenter.scss';
import {VscGitPullRequestCreate} from "react-icons/vsc";
import Modal from "react-modal";

export const ModalCenter = ({sizeModal, modalOpen, setModalOpen, children, contentLabel}) => {

    function closeModal() {
        setModalOpen(false);
    }

    let left = '25vw';
    let width = '50vw';
    if(sizeModal){
        left = (100 - Number(sizeModal)) / 2  + 'vw';
        width = Number(sizeModal)  + 'vw';
    }

    const customStyles = {
        overlay: {
            background: 'rgb(0 0 0 / 35%)',
        },
        content: {
            top: '20vh',
            height: 'fit-content',
            width: width,
            left: left,
        },
    };

    useEffect(() => {
        document.body.style.overflow = modalOpen ? "hidden" : "unset";
    }, [modalOpen]);

    return (
        <div>
            <Modal

                isOpen={modalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                closeTimeoutMS={200}
                ariaHideApp={true}
                style={customStyles}
            >
                <div className="crm__product-create-modal">
                    <div className="crm__product-create-modal-header">
                        <VscGitPullRequestCreate size="24px"/>
                        <h3>{contentLabel}</h3>
                    </div>
                    {children}
                </div>
            </Modal>
        </div>
    );
};