import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';
import './HeaderNav.scss';
import {IoMdExit} from "react-icons/io";
import {Link} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";

export const HeaderNav = ({title, children}) => {
   
    const { logout } = useAuth();

    return (
        <div className="crm__header-nav">
            <div className="crm__header-nav-left">
                <h1>{title}</h1>
                {children}
            </div>
            <div className="crm__header-nav-right">
                <span className="crm__header-nav-user">Бігун Владислав Олегович №0001</span>
                <button className="crm__nav-item" onClick={logout}>
                    <IoMdExit size="24px" color="var(--logo-blue)"/>
                </button>
            </div>
        </div>
    );
};

HeaderNav.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.symbol,
    ]),
    info: PropTypes.node,
    className: PropTypes.string,
    hasFocus: PropTypes.bool,
    isValidating: PropTypes.bool,
};
