import React from 'react';

import {useField, useFormikContext} from 'formik';
import {FormGroup} from '../FormGroup';
import Client from "../../../api/Client";
import AsyncSelect from "react-select/async";
import NovaPost from "../../../api/NovaPost";

export const WarehouseDropdownField = ({label, items, validate, placeholder, table, autoSubmit, ...props}) => {
    const {id, name} = props;
    const [
        {value, onBlur: onBlurFormik},
        {error, touched},
        {setValue, setTouched, setError}
    ] = useField(props);

    const {
        values: { city },
    } = useFormikContext();

    const loadOptions = (searchKey) => {
        return new Promise(async (resolve) => {
            if (searchKey.length >= 0) {
                const result = await NovaPost.getWarehouse(searchKey, city);
                if (result.status === 200 && result.data.length > 0) {
                    console.log(result.data)
                    resolve(result.data);
                } else {
                    resolve([]);
                }
            }
        });
    };

    const onChange = (option) => setValue(option.ref);

    return (
        <FormGroup error={touched ? error : null} {...{id, name, label}} >
            <AsyncSelect
                onChange={onChange}
                value={value.ref}
                loadOptions={loadOptions}
                placeholder={placeholder}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.ref}
                isDisabled={city === ''}
            />
        </FormGroup>
    );
};
