import React, {useEffect} from 'react';
import cx from 'classnames';
import {useField, useFormikContext} from 'formik';
import { FormGroup } from '../FormGroup';
import {NumericFormat} from 'react-number-format';

import './InputNumber.scss';

const CustomInput = React.forwardRef((props, ref) => {
    return <input ref={ref} {...props} />;
});

export const InputNumber = ({ label, mask, disabled, autoSubmit = false, ...props}) => {
    const { id, name, type, placeholder } = props;
    const {...fieldProps } = props;
    const [field, { error, touched }, helpers] = useField(fieldProps);
    const { value } = field;

    const inputClasses = cx(
        'input',
        { 'input--error': touched && error != null},
        { 'input--success': touched && error == null},
    );
    return (
        <FormGroup {...{ label, id, name }}>
            <NumericFormat className={inputClasses}
                           thousandSeparator=" "
                           decimalSeparator=","
                           suffix=" грн"
                           allowNegative={false}
                           customInput={CustomInput}
                           decimalScale={2}
                           fixedDecimalScale={true}
                           onValueChange={(values) => {
                               const raw = values.value;
                               const numericValue = raw === '' ? 0 : Number(raw);
                               helpers.setValue(numericValue);
                           }}
                       {...{
                           id: name,
                           type,
                           placeholder,
                           disabled
                       }}
            />
        </FormGroup>
    );
};