import React, {useEffect} from 'react';
import {Form, Formik, useFormikContext} from "formik";
import {Input} from "../field-components-new/Input";
import {Button} from "../Button/Button";
import Company from "../../api/Company";
import NbuApi from "../../api/NbuApi";
import {MainSelect} from "../field-components-new/Select";

export const ProductsForm = () => {
    const {
        values: { edrpou, iban },
        setFieldValue,
        errors,
        isSubmitting
    } = useFormikContext();

    const searchCompany = async() =>{
        if (edrpou) {
            if (edrpou.length >= 8) {
                const result = await Company.get({'edrpou': edrpou});
                let result_data = result.data.data[0];
                //console.log('result_data', result_data)
                if (result_data) {
                    await setFieldValue('name', result_data.name);
                    await setFieldValue('type', result_data.type);
                    await setFieldValue('address', result_data.address  ?? '');
                    await setFieldValue('iban', result_data.iban ?? '');
                    await setFieldValue('bank', result_data.bank  ?? '');
                    await setFieldValue('id', result_data.id  ?? null);
                } else{
                    await setFieldValue('name', '');
                    await setFieldValue('type', '');
                    await setFieldValue('address', '');
                    await setFieldValue('iban', '');
                    await setFieldValue('bank', '');
                    await setFieldValue('id', null);
                }
            }
        }
    }

    const BankInfo = async (mfo, setFieldValue) =>{
        await NbuApi.getBankInfo(mfo).then(function (response) {
            if(response.data.length > 0){
                let bank_name = response.data[0]?.SHORTNAME;
                setFieldValue('bank', bank_name);
            } else {
                setFieldValue('bank', '');
            }
        });
    }

    useEffect( () => {
        if (iban && !errors?.iban?.lenght && iban !== "UA") {
            let mfo = iban.substring(4, 10);
            BankInfo(mfo, setFieldValue);
        }
    }, [iban])

    useEffect(  () => {
        searchCompany()
    }, [edrpou])




    // const innerValue = useMemo(() => items.find((it) => it.value === value), [items, value]);


    return (
        <Form>
            <div className="filter">
                <Input type="text" name="search" placeholder="Назва або код товару"
                       autoSubmit={true}></Input>
                {/*<SearchDropdownField name="category" placeholder="Категорія" autoSubmit={true}/>*/}
            </div>
        </Form>
    )
};