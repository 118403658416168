import React, {useCallback, useEffect} from 'react';
import * as Yup from 'yup';
import { Input } from '../Input';
import { createFieldValidator } from '../../../utils/create-field-validator';
import {useField, useFormikContext} from "formik";

const defaultLabel = '';
const defaultPlaceholder = "Введіть номер замовлення, телефон або ПІБ\n";

export const SearchField = (props) => {
    const { label = defaultLabel, placeholder = defaultPlaceholder, ...rest } = props;

    const { submitForm } = useFormikContext();

    const [{value}] = useField(props);

    useEffect(() => {
        submitForm();
    }, [value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validate = useCallback(createFieldValidator(Yup
        .string()
        .max(30, 'Максимально 30 символів')
    ), []);

    return (
        <Input type="text" {...rest} {...{ label, placeholder, validate}} />
    );
};

